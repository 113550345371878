import useUserStore from 'entities/User.model';

const RequireAuth = ({ children }: { children: React.ReactElement }) => {
  const { identified, currentOrgUser } = useUserStore();

  if (!identified) return null;

  if (currentOrgUser?.organization.id !== '1')
    return (
      <div style={{ maxWidth: 420, textAlign: 'center' }}>
        Извините, у вас нет доступа к организации. Либо у вас отозвали доступ, либо ещё не выдали.
        Пожалуйста, обратитесь к администратору за подробностями.
      </div>
    );

  return children;
};

export default RequireAuth;
