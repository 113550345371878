import { memo, useCallback } from 'react';
import { useRef, useState } from 'react';
import LoginIcon from '@mui/icons-material/LoginRounded';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import Logout from './Logout';
import Organizations from './Organizations';
import UserInfo from './UserInfo';
import { useUserStore } from 'entities/User.model';
import Avatar from 'shared/components/Avatar';

const UserBar = () => {
  const navigate = useNavigate();
  const { identified, record } = useUserStore();
  const { user } = record;
  const [opened, setOpened] = useState(false);
  const avatarRef = useRef<HTMLDivElement>();

  const handleUserMenuOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleUserMenuClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handleSignInClick = useCallback(() => {
    navigate('/user/sign-in');
  }, []);

  return (
    <>
      <AvatarStyled ref={avatarRef} name={user.name} onClick={handleUserMenuOpen} />

      <Popover
        open={opened}
        onClose={handleUserMenuClose}
        anchorEl={avatarRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <UserInfo />
        <Organizations />
        {identified ? (
          <MenuList>
            <Logout />
          </MenuList>
        ) : (
          <MenuList>
            <MenuItem onClick={handleSignInClick}>
              <ListItemIcon>
                <LoginIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Войти в аккаунт</ListItemText>
            </MenuItem>
          </MenuList>
        )}
      </Popover>
    </>
  );
};

export default memo(UserBar);

const AvatarStyled = styled(Avatar)`
  cursor: pointer;
`;
