import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import clubService, { ClubShortFragment } from 'shared/api/club';

export type ClubStore = {
  currentClub: ClubShortFragment | null;
  fetchClub: (clubId: string) => Promise<ClubShortFragment>;
  setClub: (club: ClubShortFragment) => void;
};

export const useClubStore = create(
  immer<ClubStore, []>((set, get) => ({
    currentClub: null,

    setClub: (club) =>
      set((state) => {
        state.currentClub = club;
      }),

    async fetchClub(clubId: string) {
      try {
        const { data } = await clubService.queryClubShort(clubId);

        if (data.club.__typename === 'Club') {
          get().setClub(data.club);

          return data.club;
        }

        return Promise.reject(data.club);
      } catch (exx) {}
    },
  })),
);

export default useClubStore;
