import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

import useUserStore from 'entities/User.model';
import Avatar from 'shared/components/Avatar';

interface Props {
  active?: boolean;
  onClick?: () => void;
}

const UserInfo = ({ active, onClick }: Props) => {
  const { record } = useUserStore();
  const { user } = record;

  return (
    <UserInfoStyled active={active} onClick={onClick}>
      <Avatar name={user.name} size="large" />
      {user.name && user.name.length > 0 ? (
        <User>
          <UserName>{user.name}</UserName>
          <UserEmail>{user.email}</UserEmail>
        </User>
      ) : (
        <UserName>{user.email.length > 0 ? user.email : 'Гость'}</UserName>
      )}
      <SettingsIcon />
    </UserInfoStyled>
  );
};

export default UserInfo;

const UserInfoStyled = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--space-md);
  padding: var(--space-md);
  border-bottom: 1px solid var(--neutral-border-light);

  .avatar {
    cursor: default;
  }

  svg {
    display: none;
  }

  @media (max-width: 768px) {
    gap: var(--space-sm);
    margin: var(--space-xs);
    padding: var(--space-sm);
    border: none;
    border-radius: var(--radii-md);
    background-color: var(--primary-light);
    transition: var(--transition);
    cursor: pointer;

    svg {
      display: block;
      font-size: 24px;
      margin-left: var(--space-sm);
      transition: var(--transition);
    }

    ${({ active }) =>
      active &&
      css`
        margin-left: calc(var(--space-xs) * -1);
        margin-right: 0;
        padding-left: calc(var(--space-sm) + var(--space-xs) + var(--space-xxs));
        padding-right: calc(var(--space-sm) + var(--space-xxs));
        border-radius: 0;

        svg {
          transform: rotate(90deg);
        }
      `}
  }
`;

const User = styled.div`
  flex: 1 1 auto;
`;

const UserName = styled.div`
  font-size: var(--fz-md);
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: var(--fz-sm);
  }
`;

const UserEmail = styled.div`
  font-size: var(--fz-sm);
  color: var(--text-subtle);

  @media (max-width: 768px) {
    font-size: var(--fz-xs);
  }
`;
