import { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import ImageIcon from '@mui/icons-material/ImageRounded';
import UndoIcon from '@mui/icons-material/ReplayOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
} from '@mui/material';
import xor from 'lodash/xor';
import styled from '@emotion/styled';

import ExerciseTagsInput from './TagsInput';
import FileInput from 'features/FileInput';
import { ExerciseFragment, ExerciseTagFragment } from 'shared/api/exercises';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => Promise<void>;
  submitting: boolean;
  data?: ExerciseFragment;
}

export interface FormData {
  name: string;
  description: string;
  age: ExerciseTagFragment[];
  technical: ExerciseTagFragment[];
  tactical: ExerciseTagFragment[];
  physical: ExerciseTagFragment[];
  general: ExerciseTagFragment[];
  files: File[];
  attachmentIds: string[];
  videoLink: string;
}

const ExerciseForm = ({ open, onClose, onSubmit, submitting, data }: Props) => {
  const [invalidVideoLink, setInvalidVideoLink] = useState(false);
  const initialState: FormData = {
    name: '',
    description: '',
    age: [],
    technical: [],
    tactical: [],
    physical: [],
    general: [],
    files: [],
    attachmentIds: [],
    videoLink: '',
  };

  const formDataRef = useRef<FormData>(initialState);
  const formData = formDataRef.current;

  // eslint-disable-next-line
  const [, rerender] = useState(0);

  useEffect(() => {
    if (!data) return;
    formDataRef.current = {
      name: data.name || '',
      description: data.description || '',
      age: data.tags.filter((tag) => tag.category === 'age') || [],
      technical: data.tags.filter((tag) => tag.category === 'technical') || [],
      tactical: data.tags.filter((tag) => tag.category === 'tactical') || [],
      physical: data.tags.filter((tag) => tag.category === 'physical') || [],
      general: data.tags.filter((tag) => tag.category === 'general') || [],
      files: [],
      attachmentIds: data.attachments.map((attachment) => attachment.id),
      videoLink: data.video ? `https://video.rbdata.ru/video/${data.video.id}` : '',
    };
    rerender((prev) => prev + 1);
  }, [data]);

  // eslint-disable-next-line
  const setValue = (partialState: Record<string, any>) => {
    for (const key in partialState) {
      if (key === 'videoLink') validateVideoLink(partialState[key]);
      formDataRef.current[key] = partialState[key];
    }

    rerender((value) => value + 1);
  };

  const setFiles = (files: File[]) => {
    setValue({ files });
  };

  const handleDeleteAttachment = (id: string) => {
    setValue({ attachmentIds: xor(formData.attachmentIds, [id]) });
  };

  const handleTagsChange = (tags: ExerciseTagFragment[], category: string) => {
    setValue({ [category]: tags });
  };

  const validateVideoLink = (link: string) => {
    setInvalidVideoLink(!(!link.length || link.startsWith('https://video.rbdata.ru/video/')));
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => resetForm(), 300);
  };

  const handleSubmit = async () => {
    await onSubmit(formData);
    handleClose();
  };

  const resetForm = () => {
    setValue({
      name: '',
      description: '',
      age: [],
      technical: [],
      tactical: [],
      physical: [],
      general: [],
    });
  };

  const editMode = !!data;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {editMode ? 'Редактировать упражнение' : 'Новое упражнение'}
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          margin="dense"
          label="Название"
          autoComplete="off"
          required
          value={formData.name}
          onChange={(event) => {
            setValue({ name: event.target.value });
          }}
        />

        <TextField
          fullWidth
          multiline
          maxRows={6}
          margin="dense"
          label="Описание"
          autoComplete="off"
          required
          value={formData.description}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(event) => {
            setValue({ description: event.target.value });
          }}
        />

        <Divider sx={{ marginBlock: 'var(--space-md)' }} />

        <ExerciseTagsInput
          label="Возраст"
          category="age"
          value={formData.age}
          onChange={handleTagsChange}
        />
        <ExerciseTagsInput
          label="Техническая направленность"
          category="technical"
          value={formData.technical}
          onChange={handleTagsChange}
        />
        <ExerciseTagsInput
          label="Тактическая направленность"
          category="tactical"
          value={formData.tactical}
          onChange={handleTagsChange}
        />
        <ExerciseTagsInput
          label="Физическая направленность"
          category="physical"
          value={formData.physical}
          onChange={handleTagsChange}
        />
        <ExerciseTagsInput
          label="Общие характеристики"
          category="general"
          value={formData.general}
          onChange={handleTagsChange}
        />

        <Divider sx={{ marginBlock: 'var(--space-md)' }} />

        <Attachments>
          <FileInput
            files={formData.files}
            setFiles={setFiles}
            accept="image/*"
            label="Прикрепить картинки"
          />

          {data?.attachments.length > 0 &&
            data.attachments.map((attachment) => (
              <Attachment key={attachment.id}>
                <ImageIcon color="primary" />

                <FileName $deleted={!formData.attachmentIds.includes(attachment.id)}>
                  {attachment.name}
                </FileName>

                {!formData.attachmentIds.includes(attachment.id) ? (
                  <UndoIcon onClick={() => handleDeleteAttachment(attachment.id)} />
                ) : (
                  <DeleteIcon color="error" onClick={() => handleDeleteAttachment(attachment.id)} />
                )}
              </Attachment>
            ))}
        </Attachments>

        <TextField
          fullWidth
          margin="dense"
          label="Ссылка на Видеохранилище"
          autoComplete="off"
          error={invalidVideoLink}
          value={formData.videoLink}
          onChange={(event) => {
            setValue({ videoLink: event.target.value });
          }}
          helperText={
            invalidVideoLink ? 'Ссылка должна начинаться на "https://video.rbdata.ru/video/"' : ''
          }
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="error">
          Отмена
        </Button>
        <LoadingButton
          loading={submitting}
          onClick={handleSubmit}
          variant="contained"
          disabled={!formData.name || !formData.description || invalidVideoLink}
        >
          {editMode ? 'Сохранить' : 'Создать'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExerciseForm;

const Attachments = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  margin-bottom: var(--space-xs);
`;

const Attachment = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  padding: var(--space-xs) var(--space-sm);
  border: 1px solid var(--neutral-border);
  border-radius: var(--radii-sm);

  svg {
    width: 18px;
    height: 18px;
  }

  svg:last-child {
    cursor: pointer;
  }
`;

const FileName = styled.div<{ $deleted: boolean }>`
  flex: 1 1 auto;
  display: flex;
  align-items: baseline;
  gap: var(--space-xxs);
  font-size: var(--fz-sm);
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    color: var(--text-subtle);
  }

  ${({ $deleted }) =>
    $deleted &&
    `
    text-decoration: line-through;
    opacity: 0.5;
  `}
`;
