import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import isBetween from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useNavigate } from 'react-router-dom';

import Router, { routes } from 'app/Router';
import { useAppStore } from 'entities/App.model';
import useUserStore from 'entities/User.model';
import Notifications from 'features/Notifications';
import theme from 'shared/styles/theme';

const App = () => {
  const appStore = useAppStore();
  const userStore = useUserStore();
  const navigate = useNavigate();

  dayjs.locale('ru');
  dayjs.extend(isBetween);
  dayjs.extend(updateLocale);

  dayjs.updateLocale('ru', {
    months: (dayjsInstance, format: string) => {
      if (/^MMMM/.test(format)) {
        return [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ][dayjsInstance.month()];
      }
      return [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
      ][dayjsInstance.month()];
    },
  });

  useEffect(() => {
    if (userStore.hasAccessToken) {
      appStore.init();
    } else {
      navigate(routes.signIn);
    }
  }, [userStore.hasAccessToken]);

  useEffect(() => {
    if (userStore.authenticated) {
      navigate(routes.common);
    }
  }, [userStore.authenticated]);

  return (
    <ThemeProvider theme={theme}>
      <Notifications />
      <Router />
    </ThemeProvider>
  );
};

export default App;
