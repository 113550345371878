import { useState } from 'react';
import { MenuItem } from '@mui/material';
import difference from 'lodash/difference';

import useAttachmentStore from 'entities/Attachment.model';
import useUserStore from 'entities/User.model';
import ExerciseForm, { FormData } from 'features/ExerciseForm';
import useNotificationsStore from 'features/Notifications/Notifications.model';
import exercisesService, { ExerciseFragment, ExerciseShortFragment } from 'shared/api/exercises';

interface Props {
  exercise: ExerciseShortFragment;
  onUpdate: (exercise: ExerciseFragment) => void;
}

const EditExercise = ({ exercise, onUpdate }: Props) => {
  const { currentOrgUser } = useUserStore();
  const notification = useNotificationsStore();
  const [openDialog, setOpenDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fullExerciseData, setFullExerciseData] = useState<ExerciseFragment>();

  const handleOpenDialog = async () => {
    // check if exercise is ExerciseFragment type
    const result = await exercisesService.queryExercise({
      orgId: currentOrgUser.organization.id,
      ids: [exercise.id],
    });
    setFullExerciseData(result);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEdit = async (data: FormData) => {
    setSubmitting(true);
    let newAttachmentIds: string[] = [];
    newAttachmentIds = await Promise.all(
      data.files.map((file) => useAttachmentStore.getState().sendAttachment(file)),
    );
    const result = await exercisesService.updateExercise({
      id: exercise.id,
      name: data.name,
      description: data.description,
      tagIds: [
        ...data.age,
        ...data.technical,
        ...data.tactical,
        ...data.physical,
        ...data.general,
      ].map((tag) => tag.id),
      newAttachmentIds,
      deletedAttachmentIds: difference(
        fullExerciseData.attachments.map((attachment) => attachment.id),
        data.attachmentIds,
      ),
      videoId: data.videoLink.length > 0 ? data.videoLink.split('/').pop() : undefined,
    });
    setSubmitting(false);
    onUpdate(result);
    notification.send({ title: 'Упражнение обновлено', type: 'success' });
  };

  return (
    <>
      <MenuItem onClick={handleOpenDialog}>Редактировать</MenuItem>

      <ExerciseForm
        open={openDialog}
        onClose={handleCloseDialog}
        data={fullExerciseData}
        submitting={submitting}
        onSubmit={handleEdit}
      />
    </>
  );
};

export default EditExercise;
