import { useEffect, useRef, useState } from 'react';
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';
import videojs from 'video.js';
import 'videojs-mobile-ui';
import 'videojs-mobile-ui/dist/videojs-mobile-ui.css';
import 'video.js/dist/video-js.css';
import styled from '@emotion/styled/macro';

interface Props {
  options: {
    autoplay?: boolean;
    controls: boolean;
    fill: boolean;
    fluid: boolean;
    playbackRates?: number[];
    sources: string[] | { src: string; type: string }[];
    hotkeys?: 'on' | 'off' | 'fullscreen';
  };
}

const Player = ({ options }: Props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const [mainRate, setMainRate] = useState<number>(1);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(
        videoElement,
        {
          ...options,
          controlBar: {
            volumePanel: {
              inline: false,
            },
            remainingTimeDisplay: false,
            pictureInPictureToggle: false,
          },
        },
        () => {
          playerRef.current.on('ratechange', () => {
            if (!isHotkeyPressed('right')) {
              setMainRate(playerRef.current.playbackRate());
            }
          });
        },
      );
      playerRef.current.mobileUi();
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  // HOTKEYS CONFIGURATION

  const disabledHotkey = () =>
    options.hotkeys === 'off' ||
    (options.hotkeys === 'fullscreen' && !playerRef.current?.isFullscreen());

  const playPause = () => {
    if (disabledHotkey()) return;
    playerRef.current.paused() ? playerRef.current.play() : playerRef.current.pause();
  };

  const fullScreen = () => {
    if (disabledHotkey()) return;
    setTimeout(() => {
      playerRef.current.isFullscreen()
        ? playerRef.current.exitFullscreen()
        : playerRef.current.requestFullscreen();
    }, 300);
  };

  const mute = () => {
    if (disabledHotkey()) return;
    playerRef.current.muted(!playerRef.current.muted());
  };

  const jumpBackward = () => {
    if (disabledHotkey()) return;
    playerRef.current.currentTime(playerRef.current.currentTime() - 1);
  };

  const prevFrame = () => {
    if (disabledHotkey()) return;
    playerRef.current.currentTime(playerRef.current.currentTime() - 1 / 30);
    playerRef.current.pause();
  };

  const nextFrame = () => {
    if (disabledHotkey()) return;
    playerRef.current.currentTime(playerRef.current.currentTime() + 1 / 30);
    playerRef.current.pause();
  };

  const halfSpeed = () => {
    if (disabledHotkey()) return;
    playerRef.current.play();
    playerRef.current.playbackRate(0.5);
  };

  const tripleSpeed = () => {
    if (disabledHotkey()) return;
    playerRef.current.play();
    playerRef.current.playbackRate(3);
  };

  const fiveSpeed = () => {
    if (disabledHotkey()) return;
    playerRef.current.play();
    playerRef.current.playbackRate(5);
  };

  const normalSpeed = () => {
    if (disabledHotkey()) return;
    playerRef.current.play();
    playerRef.current.playbackRate(mainRate);
  };

  useHotkeys('space', playPause);
  useHotkeys('f', fullScreen);
  useHotkeys('m', mute);
  useHotkeys('left', prevFrame, { keydown: true, preventDefault: true });
  useHotkeys('right', halfSpeed, { keydown: true });
  useHotkeys('meta+right', nextFrame, { keydown: true, preventDefault: true });
  useHotkeys('alt+right', fiveSpeed, { keydown: true });
  useHotkeys('shift+left', jumpBackward, { preventDefault: true });
  useHotkeys('shift+right', tripleSpeed, { keydown: true });
  useHotkeys('right, alt+right, shift+right', normalSpeed, { keyup: true });

  return (
    <div data-vjs-player>
      <Video ref={videoRef} />
    </div>
  );
};

export default Player;

const Video = styled.div`
  container-type: inline-size;

  * {
    outline: none;
  }

  .video-js,
  .vjs-tech {
    border-radius: var(--radii-md);
  }

  .vjs-control-bar {
    opacity: 1 !important;
    font-size: var(--fz-xs);
    border-radius: 0 0 var(--radii-md) var(--radii-md);
    user-select: none;
  }

  .vjs-menu-content {
    max-height: none !important;
  }

  .vjs-volume-panel {
    width: 4em !important;
  }

  .vjs-time-control {
    display: block !important;
    padding: 0 var(--space-xs) !important;

    &.vjs-time-divider {
      padding: 0 !important;
    }
  }

  .vjs-time-control.vjs-time-divider {
    flex: 0 0 auto;
    display: block;
    min-width: auto;
    padding: 0;
    text-align: center;
  }

  .video-js .vjs-big-play-button {
    width: 60px;
    height: 60px;
    margin: 0;
    border-radius: 50%;
    line-height: 57px;
    background-color: var(--primary) !important;
    border-width: 2px;
    transform: translateX(-50%) translateY(-50%);
    transition: var(--transition) !important;
    box-sizing: border-box;

    &:hover {
      background-color: var(--primary-hover) !important;
    }

    .vjs-icon-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 0;

      &:before {
        font-size: 32px;
        position: static;
      }
    }
  }

  .vjs-has-started .vjs-big-play-button,
  .video-js .vjs-play-progress .vjs-time-tooltip {
    display: none !important;
  }

  .video-js .vjs-control:focus,
  .video-js .vjs-slider:focus {
    visibility: hidden;
  }

  @container (max-width: 550px) {
    .video-js .vjs-button {
      width: 3.5em;
    }

    .vjs-volume-panel {
      display: none !important;
    }

    .vjs-time-control {
      padding: 0 var(--space-xxs) !important;
      &.vjs-time-divider {
        padding: 0 !important;
      }
    }
  }
`;
