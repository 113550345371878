import MoreIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import DeleteExercise from './DeleteExercise';
import EditExercise from './EditExercise';
import { ExerciseShortFragment } from 'shared/api/exercises';

interface Props {
  exercise: ExerciseShortFragment;
  onUpdate: (exercise: ExerciseShortFragment) => void;
  onDelete: (id: string) => void;
}

const ExerciseActions = ({ exercise, onUpdate, onDelete }: Props) => {
  const popupState = usePopupState({ variant: 'popover' });

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    popupState.open(event.currentTarget);
  };

  return (
    <>
      <IconButton {...bindTrigger(popupState)} onClick={handleOpenMenu}>
        <MoreIcon fontSize="small" />
      </IconButton>

      <Menu
        {...bindPopover(popupState)}
        onClick={(e) => e.preventDefault()}
        elevation={3}
        disableAutoFocusItem
      >
        <EditExercise exercise={exercise} onUpdate={onUpdate} />
        <DeleteExercise exercise={exercise} onDelete={onDelete} />
      </Menu>
    </>
  );
};

export default ExerciseActions;
