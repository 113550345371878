import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import attachmentService from 'shared/api/attachment';

export type AttachmentStore = {
  sendAttachment: (file: File) => Promise<string>;
};

export const useAttachmentStore = create(
  immer<AttachmentStore, []>(() => ({
    sendAttachment: async (file) => {
      const { directUploadUrl, id: attachmentId } = await attachmentService.createAttachment({
        filename: file.name,
        name: file.name,
        byteSize: file.size,
        contentType: file.type,
      });

      const createFile = async (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            resolve(reader.result?.toString() || '');
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      };

      const uploadFile = async (): Promise<string> => {
        const fileAsBlob = await createFile(file);

        const binary = atob(fileAsBlob.split(',')[1]);

        const blob = new Blob([new Uint8Array(binary.length).map((_, i) => binary.charCodeAt(i))]);

        await fetch(directUploadUrl, {
          method: 'PUT',
          body: blob,
          headers: {
            'Content-Type': file.type,
          },
        }).catch((error) => {
          throw error;
        });

        return completeLoad();
      };

      const completeLoad = async () => {
        await attachmentService.completeAttachment({ id: attachmentId });
        return attachmentId;
      };

      const id = await uploadFile();

      return id;
    },
  })),
);

export default useAttachmentStore;
