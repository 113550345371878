import { graphql } from 'gql';

import { client } from './client';

const COUNTRIES_QUERY = graphql(`
  query Countries($query: String) {
    countries(query: $query) {
      ... on CountryConnection {
        nodes {
          id
          name
        }
      }
      ... on CountriesQueryDomainError {
        code
        message
      }
    }
  }
`);

const CITIES_QUERY = graphql(`
  query Cities($countryId: ID, $query: String) {
    cities(countryId: $countryId, query: $query) {
      ... on CityConnection {
        nodes {
          id
          name
        }
      }
      ... on CitiesQueryDomainError {
        code
        message
      }
    }
  }
`);

const geographyService = {
  searchCountries: async (query: string) => {
    const result = await client.query({
      query: COUNTRIES_QUERY,
      variables: { query },
    });

    if (result.data.countries.__typename === 'CountriesQueryDomainError') {
      return Promise.reject(result.data.countries);
    }

    return result.data.countries.nodes;
  },

  searchCities: async (countryId: string, query: string) => {
    const result = await client.query({
      query: CITIES_QUERY,
      variables: { countryId, query },
    });

    if (result.data.cities.__typename === 'CitiesQueryDomainError') {
      return Promise.reject(result.data.cities);
    }

    return result.data.cities.nodes;
  },
};

export default geographyService;
