import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';

import useUserStore from 'entities/User.model';
import { ExerciseTagCategoriesEnum } from 'gql/graphql';
import exercisesService, { ExerciseTagFragment } from 'shared/api/exercises';

interface Props {
  label: string;
  category: string;
  value: ExerciseTagFragment[];
  onChange: (tags: ExerciseTagFragment[], category: string) => void;
}

const filter = createFilterOptions<ExerciseTagFragment>();

const ExerciseTagsInput = ({ label, category, value, onChange }: Props) => {
  const orgId = useUserStore.getState().currentOrgUser.organization.id;
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<ExerciseTagFragment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = () => {
    setLoading(true);
    exercisesService
      .queryExerciseTags({
        orgId,
        categories: [category as ExerciseTagCategoriesEnum],
      })
      .then((tags) => {
        setOptions(tags);
      })
      .catch(() => {
        setOptions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createNewTag = async () => {
    const newTag = await exercisesService.createExerciseTag({
      orgId,
      name: capitalize(inputValue),
      category: category as ExerciseTagCategoriesEnum,
    });
    onChange([...value, newTag], category);
    setOptions([...options, newTag]);
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleTagChange = (event: React.ChangeEvent<{}>, newValue: ExerciseTagFragment[]) => {
    const newItem = newValue[newValue.length - 1];
    if (newItem && newItem.id.startsWith('new-')) {
      return createNewTag();
    }
    onChange(newValue, category);
  };

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={handleTagChange}
      filterOptions={(options, params) => {
        const filtered = sortBy(filter(options, params), 'name');
        const isExisting = options.some((option) => capitalize(inputValue) === option.name);

        if (params.inputValue !== '' && !isExisting) {
          filtered.push({
            id: `new-${params.inputValue}`,
            name: `Создать тег "${capitalize(params.inputValue)}"`,
            category: category,
          } as ExerciseTagFragment);
        }

        return filtered;
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={label}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ExerciseTagsInput;
