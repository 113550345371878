import { graphql } from 'gql';

import { client } from './client';
import { AttachmentCompleteInput, AttachmentCreateInput } from 'gql/graphql';

export type { AttachmentFragment } from 'gql/graphql';

graphql(`
  fragment Attachment on Attachment {
    byteSize
    contentType
    id
    name
    presignedUrl
    status
  }
`);

const CREATE_ATTACHMENT_MUTATION = graphql(`
  mutation CreateAttachment($input: AttachmentCreateInput!) {
    attachment {
      create(input: $input) {
        attachment {
          id
          directUploadUrl
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const COMPLETE_ATTACHMENT_MUTATION = graphql(`
  mutation CompleteAttachment($input: AttachmentCompleteInput!) {
    attachment {
      complete(input: $input) {
        attachment {
          id
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const attachmentService = {
  createAttachment: async (input: AttachmentCreateInput) => {
    const result = await client.mutate({
      mutation: CREATE_ATTACHMENT_MUTATION,
      variables: { input },
    });

    if (result?.data?.attachment?.create?.error) {
      return Promise.reject(result.data.attachment.create.error);
    }

    return result.data.attachment.create.attachment;
  },

  completeAttachment: async (input: AttachmentCompleteInput) => {
    const result = await client.mutate({
      mutation: COMPLETE_ATTACHMENT_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data?.attachment?.complete?.error) {
      return Promise.reject(data.attachment.complete.error);
    }

    return data.attachment.complete.attachment;
  },
};

export default attachmentService;
