import { useState } from 'react';
import LoginIcon from '@mui/icons-material/LoginRounded';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import Logout from './Logout';
import { MobileMenuItem } from './MobileMenu';
import Organizations from './Organizations';
import UserInfo from './UserInfo';
import useUserStore from 'entities/User.model';

const UserbarMobile = () => {
  const navigate = useNavigate();
  const { identified } = useUserStore();
  const [opened, setOpened] = useState(false);

  const handleToggleMenu = () => {
    setOpened(!opened);
  };

  return (
    <>
      <UserInfo active={opened} onClick={handleToggleMenu} />

      <Collapse in={opened} sx={{ flex: '0 0 auto' }}>
        <UserMenu>
          <Organizations />

          {identified ? (
            <Logout />
          ) : (
            <MobileMenuItem onClick={() => navigate('/user/sign-in')}>
              <LoginIcon /> Войти в аккаунт
            </MobileMenuItem>
          )}
        </UserMenu>
      </Collapse>
    </>
  );
};

export default UserbarMobile;

const UserMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  padding: var(--space-xs);
  padding-top: 0;
`;
