import styled from '@emotion/styled/macro';

import Player from './Player';

interface Props {
  sources: string[] | { src: string; type: string }[];
  hotkeys?: 'on' | 'off' | 'fullscreen';
}

const VideoPlayer = ({ sources, hotkeys = 'on' }: Props) => {
  return (
    <Wrapper>
      <Player
        options={{
          sources,
          autoplay: false,
          controls: true,
          fill: true,
          fluid: true,
          playbackRates: [0.5, 1, 2, 3, 5],
          hotkeys,
        }}
      />
    </Wrapper>
  );
};

export default VideoPlayer;

const Wrapper = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;
