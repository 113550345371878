import React, { memo, useEffect } from 'react';
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from 'notistack';
import styled from '@emotion/styled/macro';

import useNotificationsStore from './Notifications.model';

const Container = () => {
  const { enqueueSnackbar } = useSnackbar();
  const notificationsStore = useNotificationsStore();
  const { message } = notificationsStore;

  useEffect(() => {
    if (message) {
      if (message.error && message.error.message) {
        enqueueSnackbar(message.error.message, { variant: message.type });
      }

      enqueueSnackbar(message.title, {
        variant: message.type,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
  }, [message]);

  return <React.Fragment />;
};

const Notifications = () => {
  return (
    <SnackbarProvider
      Components={{
        default: NotificationStyled,
        success: NotificationStyled,
        error: NotificationStyled,
        info: NotificationStyled,
        warning: NotificationStyled,
      }}
      maxSnack={6}
    >
      <Container />
    </SnackbarProvider>
  );
};

export default memo(Notifications);

const NotificationStyled = styled(MaterialDesignContent)`
  &.notistack-MuiContent-default {
    background-color: var(--text);
  }
  &.notistack-MuiContent-success {
    background-color: var(--success);
  }
  &.notistack-MuiContent-error {
    background-color: var(--error);
  }
  &.notistack-MuiContent-info {
    background-color: var(--primary-light);
  }
  &.notistack-MuiContent-warning {
    background-color: var(--warning);
  }
`;
