import { ruRU } from '@mui/material/locale';
import { Components, createTheme, Palette } from '@mui/material/styles';
import { cyan, green, indigo, orange, red, slate } from '@radix-ui/colors';

declare module '@mui/material/styles' {
  interface Palette {
    default: Palette['primary'];
  }
  interface PaletteOptions {
    default: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true;
  }
}

const theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: indigo.indigo9,
        light: indigo.indigo8,
        dark: indigo.indigo10,
        contrastText: '#fff',
      },
      secondary: {
        main: slate.slate11,
        light: slate.slate10,
        dark: slate.slate12,
        contrastText: '#fff',
      },
      error: {
        main: red.red9,
        light: red.red8,
        dark: red.red10,
        contrastText: '#fff',
      },
      warning: {
        main: orange.orange9,
        light: orange.orange8,
        dark: orange.orange10,
        contrastText: '#fff',
      },
      info: {
        main: cyan.cyan9,
        light: cyan.cyan8,
        dark: cyan.cyan10,
        contrastText: '#fff',
      },
      success: {
        main: green.green9,
        light: green.green8,
        dark: green.green10,
        contrastText: '#fff',
      },
    } as Palette,

    typography: {
      fontFamily: 'Jost',
      fontSize: 14,
      htmlFontSize: 14,
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '4px 16px',
            textTransform: 'none',
            fontWeight: 400,
            borderRadius: 50,
          },
          sizeMedium: {
            padding: '4px 16px',
            '&.MuiButton-outlined': {
              padding: '3px 15px',
            },
          },
          sizeLarge: {
            padding: '8px 24px',
            '&.MuiButton-outlined': {
              padding: '7px 23px',
            },
          },
          sizeSmall: {
            padding: '2px 12px',
            '&.MuiButton-outlined': {
              padding: '1px 11px',
            },
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:active, &.MuiButton-active': {
              boxShadow: 'none',
            },
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          edgeEnd: {
            marginRight: 0,
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: 'var(--fz-xs)',
            fontWeight: 400,
            background: 'rgba(0,0,0, .85)',
            borderRadius: 'var(--radii-md)',
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            gap: 'var(--space-xxs)',
            marginLeft: '-6px',
          },
          label: {
            marginTop: '2px',
            fontSize: 'var(--fz-sm)',
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 'var(--radii-md)',
          },
          paperFullScreen: {
            borderRadius: 0,
          },
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 'var(--space-md)',
          },
        },
      },

      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 'var(--space-md)',
          },
        },
      },

      MuiDialogContentText: {
        styleOverrides: {
          root: {
            fontSize: 'var(--fz-md)',
            color: 'var(--text-subtle)',
          },
        },
      },

      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 'var(--space-md)',
          },
        },
      },

      MuiPopover: {
        styleOverrides: {
          root: {
            margin: 'var(--space-xs) 0',
          },
          paper: {
            borderRadius: 'var(--radii-md)',
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: 'var(--space-xs) var(--space-sm)',
            fontSize: 'var(--fz-sm)',
            '&:hover': {
              backgroundColor: 'var(--neutral-light)',
            },

            '&.Mui-selected': {
              backgroundColor: 'var(--primary-light)',
              color: 'var(--primary-dark)',

              '&:hover': {
                backgroundColor: 'var(--primary-light)',
                color: 'var(--primary-dark)',
              },
            },

            '& + .MuiDivider-root': {
              marginTop: 'var(--space-xxs)',
              marginBottom: 'var(--space-xxs)',
            },
          },
        },
      },

      MuiList: {
        styleOverrides: {
          root: {
            padding: 'var(--space-xxs) 0',
          },
        },
      },

      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontSize: 'var(--fz-sm)',
            fontWeight: 400,
            color: 'var(--text-subtle)',
            lineHeight: '2.5rem',
          },
        },
      },

      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'var(--text-subtle)',
          },
        },
      },

      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 'var(--fz-md)',
          },
          secondary: {
            fontSize: 'var(--fz-sm)',
          },
          multiline: {
            whiteSpace: 'normal',
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: 'var(--fz-sm)',
          },
        },
      },

      MuiInput: {
        styleOverrides: {
          underline: {
            '.MuiAutocomplete-endAdornment.MuiAutocomplete-endAdornment': {
              right: 0,
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: 'var(--fz-sm)',
            '&&&': {
              padding: 0,
              borderRadius: 'var(--radii-md)',
            },
          },
          input: {
            padding: 'var(--space-sm) !important',
          },
          inputSizeSmall: {
            padding: 'var(--space-xs) var(--space-sm) !important',
          },
          adornedStart: {
            '&&&': {
              paddingLeft: 'var(--space-sm)',
            },
          },
          inputAdornedStart: {
            '&&&': {
              marginLeft: 0,
            },
          },
        },
      },

      MuiAutocomplete: {
        defaultProps: {
          slotProps: {
            paper: {
              elevation: 4,
            },
          },
          ChipProps: {
            size: 'small',
          },
        },
        styleOverrides: {
          inputRoot: {
            gap: 'var(--space-xs)',
            padding: 'var(--space-sm) !important',

            '&:has(.MuiInputBase-inputSizeSmall)': {
              padding: 'var(--space-xs) var(--space-sm) !important',
            },
          },
          input: {
            padding: '0 !important',
          },
          endAdornment: {
            '&&&': {
              top: '50%',
              right: 'var(--space-xs)',
              transform: 'translateY(-50%)',
            },
          },
          tag: {
            margin: '0',
            height: '20px',
          },
          clearIndicator: {
            margin: 0,
            padding: 0,
          },
          popupIndicator: {
            margin: 0,
            padding: 0,
          },
          popper: {
            margin: 'var(--space-xs) 0 !important',
          },
          listbox: {
            padding: 'var(--space-xxs) 0',
          },
          option: {
            fontSize: 'var(--fz-sm)',
            padding: 'var(--space-xs) var(--space-sm)',
            '&:hover': {
              backgroundColor: 'var(--neutral-light)',
            },
            '&[aria-selected="true"]': {
              backgroundColor: 'var(--neutral-light)',
            },
          },
          noOptions: {
            fontSize: 'var(--fz-sm)',
            padding: 'var(--space-xs) var(--space-sm)',
          },
          loading: {
            fontSize: 'var(--fz-sm)',
            padding: 'var(--space-xs) var(--space-sm)',
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: 'var(--fz-sm)',
            color: 'var(--text-subtle)',
            transform: 'translate(var(--space-sm), 13px) scale(1)',

            '&:hover + .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.87)',
            },
          },
          sizeSmall: {
            transform: 'translate(var(--space-sm), 8px) scale(1)',
          },
          shrink: {
            transform: 'translate(var(--space-sm), -8px) scale(0.8)',
            letterSpacing: '.1px',
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            legend: {
              fontSize: 'calc(var(--fz-sm) * 0.8)',
            },
          },
        },
      },

      MuiInputAdornment: {
        styleOverrides: {
          positionStart: {
            marginRight: 0,
          },
          positionEnd: {
            paddingRight: 'var(--space-xs)',
          },
        },
      },

      MuiSelect: {
        defaultProps: {
          MenuProps: {
            elevation: 4,
          },
        },
        styleOverrides: {
          icon: {
            marginRight: '2px',
          },
        },
      },

      MuiCheckbox: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            padding: 'var(--space-xxs)',
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            cursor: 'inherit',
          },
          clickable: {
            cursor: 'pointer',
          },
          sizeSmall: {
            fontSize: 'var(--fz-xs)',
            letterSpacing: '.3px',
            '.MuiChip-icon': {
              fontSize: 14,
              marginLeft: 5,
            },
          },
          filled: {
            padding: '1px',
          },
          icon: {
            color: 'inherit',
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: 'var(--fz-sm)',
            letterSpacing: '.75px',
            padding: '0 var(--space-md)',
            color: 'var(--text-subtle)',
          },
          textColorInherit: {
            opacity: 1,
          },
        },
      },

      MuiDateCalendar: {
        styleOverrides: {
          root: {
            maxHeight: 'none',
          },
        },
      },

      MuiPickersMonth: {
        styleOverrides: {
          monthButton: {
            fontSize: 'var(--fz-md)',

            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.3)',
            },
          },
        },
      },

      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            fontSize: 'var(--fz-md)',

            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.3)',
            },
          },
        },
      },

      MuiYearCalendar: {
        styleOverrides: {
          root: {
            maxHeight: '208px',
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            margin: 'var(--space-xxs)',
            color: 'var(--neutral)',
            fontSize: 'var(--fz-xs)',
          },
        },
      },
    } as Components,
  },
  ruRU,
);

export default theme;
