import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { GQLType } from 'shared/api';

type Message = {
  title: string;
  type: 'default' | 'success' | 'error' | 'info' | 'warning';
  error?: GQLType.ServerError;
};

export type NotificationsStore = {
  message?: Message;

  send: (message: Message) => void;
  clear: () => void;
};

export const useNotificationsStore = create(
  immer<NotificationsStore, []>((set) => ({
    clear: () =>
      set((state) => {
        state.message = null;
      }),

    send: (message) =>
      set((state) => {
        state.message = message;
      }),
  })),
);

export default useNotificationsStore;
