import { ApolloProvider } from '@apollo/client';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import { client } from './shared/api/client';
import './shared/styles/global.css';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </LocalizationProvider>,
);
