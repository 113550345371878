import { useState } from 'react';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import * as MUIDatePicker from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  defaultValue?: Dayjs | string | null;
  value: Dayjs | string | null;
  onChange: (date: Dayjs) => void;
  label?: string;
  size?: 'small' | 'medium';
  margin?: 'none' | 'dense' | 'normal';
  hasError?: boolean;
  helperText?: string;
  textFieldProps?: any;
  views?: ('year' | 'month' | 'day')[];
  openTo?: 'year' | 'month' | 'day';
  format?: string;
  maxDate?: Dayjs | string | null;
  minDate?: Dayjs | string | null;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  disableBefore?: Dayjs | string | null;
  disableAfter?: Dayjs | string | null;
  openOnFocus?: boolean;
  required?: boolean;
  clearButton?: boolean;
}

const DatePicker = ({
  label,
  size = 'medium',
  margin = 'none',
  hasError,
  helperText,
  defaultValue,
  value,
  onChange,
  textFieldProps,
  views,
  openTo,
  format = 'DD.MM.YYYY',
  maxDate,
  minDate,
  disabled = false,
  disablePast = false,
  disableFuture = false,
  disableBefore,
  disableAfter,
  openOnFocus,
  required,
  clearButton,
}: Props) => {
  const [open, setOpen] = useState(false);

  const disableDate = (date: Dayjs) => {
    if (disableBefore && date.isBefore(disableBefore, 'day')) {
      return true;
    }
    if (disableAfter && date.isAfter(disableAfter, 'day')) {
      return true;
    }
    return false;
  };

  const disableYear = (year: Dayjs) => {
    if (
      disableBefore &&
      dayjs(`${year.year()}-${dayjs(value).month() + 1}-01`).isBefore(disableBefore, 'month')
    ) {
      return true;
    }

    if (
      disableAfter &&
      dayjs(`${year.year()}-${dayjs(value).month() + 1}-01`).isAfter(disableAfter, 'month')
    ) {
      return true;
    }

    return false;
  };

  return (
    <MUIDatePicker.DatePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      format={format}
      views={views}
      openTo={openTo}
      maxDate={maxDate}
      minDate={minDate}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      shouldDisableDate={disableDate}
      shouldDisableMonth={disableDate}
      shouldDisableYear={disableYear}
      yearsPerRow={3}
      reduceAnimations
      slotProps={{
        textField: {
          label,
          size,
          margin,
          error: hasError,
          helperText,
          fullWidth: true,
          required,
          onClick: () => openOnFocus && setOpen(true),
          sx: {
            '*': {
              color: hasError ? 'var(--error)' : '',
              cursor: openOnFocus ? 'pointer' : 'inherit',
            },
          },
          inputProps: {
            readOnly: openOnFocus,
            sx: {
              userSelect: openOnFocus ? 'none' : 'auto',
            },
          },
          InputLabelProps: {
            sx: {
              pointerEvents: openOnFocus ? 'auto' : 'none',
            },
          },
          ...textFieldProps,
        },
        openPickerButton: {
          size: 'small',
        },
        openPickerIcon: {
          sx: {
            fontSize: 'var(--fz-md)',
          },
        },
        ...(clearButton && { actionBar: { actions: ['clear'] } }),
      }}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
    />
  );
};

export default DatePicker;
