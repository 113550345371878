import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Outlet } from 'react-router-dom';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

import Header from 'shared/components/header';

interface Props {
  fullScreen?: boolean;
}

const Layout = ({ fullScreen }: Props) => (
  <Main>
    <Header />
    <Container fullScreen={fullScreen}>
      <Content id="layout-content" fullScreen={fullScreen}>
        <Suspense fallback={<CircularProgress sx={{ margin: '2rem 0' }} />}>
          <Outlet />
        </Suspense>
      </Content>
    </Container>
  </Main>
);

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100dvh;
`;

const Container = styled.div<{ fullScreen?: boolean }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin: 0 auto;
  padding: var(--space-xl);
  overflow: ${({ fullScreen }) => (fullScreen ? 'hidden' : 'scroll')};

  @media (max-width: 768px) {
    padding: 0;
  }

  @media print {
    width: 11.7in;
    min-height: 8.27in;
    padding: 0;
  }
`;

const Content = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: ${({ fullScreen }) => (fullScreen ? '100%' : '1200px')};
  min-height: ${({ fullScreen }) => (fullScreen ? '100%' : 'auto')};
  padding: var(--space-xl);
  background-color: var(--neutral-bg);
  border: 1px solid var(--slate-4);
  border-radius: var(--radii-lg);
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      overflow-y: scroll;
      overflow-x: hidden;
    `}

  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: var(--space-md);
    border: none;
    border-radius: 0;
  }

  @media print {
    width: 11.7in;
    min-height: 8.27in;
    padding: 0.25in;
    padding-bottom: 0;
    border: none;
    border-radius: 0;
  }
`;

export default Layout;
