import { graphql } from 'gql';

import { client } from './client';

export type { AgeGroupShortFragment } from 'gql/graphql';

graphql(`
  fragment AgeGroupShort on AgeGroup {
    id
    name
  }
`);

const AGE_GROUPS_QUERY = graphql(`
  query AgeGroups($first: Int) {
    ageGroups(first: $first) {
      ... on AgeGroupConnection {
        edges {
          node {
            ...AgeGroupShort
          }
        }
      }
    }
  }
`);

const ageGroupService = {
  queryAgeGroups: async () => {
    const result = await client.query({
      query: AGE_GROUPS_QUERY,
      variables: {
        first: 100,
      },
    });

    return result.data.ageGroups.edges;
  },
};

export default ageGroupService;
