import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { bindDialog, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import useNotificationsStore from 'features/Notifications/Notifications.model';
import { ExerciseShortFragment } from 'shared/api/exercises';
import exercisesService from 'shared/api/exercises';

interface Props {
  exercise: ExerciseShortFragment;
  onDelete: (id: string) => void;
}

const DeleteExercise = ({ exercise, onDelete }: Props) => {
  const [deleting, setDeleting] = useState(false);
  const popupState = usePopupState({ variant: 'dialog' });
  const notification = useNotificationsStore();

  const handleOpenDialog = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    popupState.open(event.currentTarget);
  };

  const handleCloseDialog = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    popupState.close();
  };

  const handleDelete = async () => {
    setDeleting(true);
    await exercisesService.deleteExercise({ id: exercise.id });
    setDeleting(false);
    popupState.close();
    onDelete(exercise.id);
    notification.send({ title: 'Упражнение удалено', type: 'success' });
  };

  return (
    <>
      <MenuItem {...bindTrigger(popupState)} onClick={handleOpenDialog}>
        Удалить
      </MenuItem>

      <Dialog {...bindDialog(popupState)} fullWidth maxWidth="xs">
        <DialogTitle>Удалить упражнение?</DialogTitle>

        <DialogContent>
          <p>Вы уверены, что хотите удалить это упражнение?</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} variant="outlined">
            Отмена
          </Button>
          <LoadingButton
            loading={deleting}
            onClick={handleDelete}
            variant="contained"
            color="error"
          >
            Удалить
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteExercise;
