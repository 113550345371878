import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/CloseRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import styled from '@emotion/styled/macro';

import useNotificationsStore from 'features/Notifications/Notifications.model';
import { GQLType } from 'shared/api';
import ageGroupService from 'shared/api/ageGroup';
import teamService from 'shared/api/team';

const schema = yup.object({
  name: yup.string().required(),
  nameRu: yup.string().required(),
  tmId: yup.string(),
  mffId: yup.string(),
  ageGroup: yup
    .object({
      id: yup.string().required(),
      name: yup.string(),
    })
    .required(),
});

export type FormData = yup.InferType<typeof schema>;

interface Props {
  visible: boolean;
  close: () => void;
  onCreate: (player: GQLType.TeamShortFragment) => void;
}

const CreateTeamDialog = ({ visible, close, onCreate }: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [ageGroups, setAgeGroups] = useState([]);
  const [ageGroupsLoading, setAgeGroupsLoading] = useState(false);
  const notificationStore = useNotificationsStore();

  useEffect(() => {
    fetchAgeGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAgeGroups = async () => {
    try {
      setAgeGroupsLoading(true);
      const result = await ageGroupService.queryAgeGroups();
      setAgeGroupsLoading(false);
      setAgeGroups(result.map((record) => record.node));
    } catch (exx) {
      notificationStore.send({
        title: 'Не удалось загрузить возрастные группы',
        error: exx,
        type: 'error',
      });
      setAgeGroupsLoading(false);
    }
  };

  const handleCreate = async (data: FormData) => {
    try {
      setLoading(true);
      const result = await teamService.createTeam({
        name: data.name,
        nameRu: data.nameRu,
        tmId: data.tmId,
        ageGroupId: data.ageGroup.id,
        mffId: data.mffId,
      });

      onCreate(result);
      setLoading(false);
    } catch (exx) {
      notificationStore.send({ title: 'Не удалось создать команду', error: exx, type: 'error' });
      setLoading(false);
    }
  };

  const handleChangeAgeGroup = (event, value: GQLType.AgeGroupShortFragment) => {
    setValue('ageGroup', value);
    clearErrors('ageGroup');
  };

  const form = () => (
    <Form>
      <Row>
        <Controller
          name="nameRu"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.nameRu)}
              margin="dense"
              label="Имя на русском"
              fullWidth
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              error={Boolean(errors.name)}
              label="Имя на английском"
              fullWidth
            />
          )}
        />
      </Row>
      <Row>
        <Controller
          control={control}
          name="ageGroup"
          render={({ field }) => (
            <Autocomplete
              fullWidth
              options={ageGroups}
              getOptionLabel={(option) => option?.name}
              value={field.value}
              onChange={handleChangeAgeGroup}
              loading={ageGroupsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Возрастная группа"
                  margin="dense"
                  error={Boolean(errors.ageGroup)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {ageGroupsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      </Row>
      <Row>
        <Controller
          name="tmId"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.tmId)}
              label="Ссылка на Transfermarkt"
              fullWidth
              margin="dense"
            />
          )}
        />
      </Row>
      <Row>
        <Controller
          name="mffId"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={Boolean(errors.tmId)}
              label="Ссылка на MFF"
              fullWidth
              margin="dense"
            />
          )}
        />
      </Row>
    </Form>
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={visible} onClose={close}>
      <form>
        <DialogTitle>
          Создать новую команду
          <IconButton size="small" onClick={close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{form()}</DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit(handleCreate)}
            type="submit"
            size="medium"
            variant="contained"
          >
            Создать
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
`;

const Row = styled.div`
  display: flex;
  gap: var(--space-sm);
`;

export default CreateTeamDialog;
