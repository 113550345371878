import { forwardRef, memo } from 'react';
import PersonIcon from '@mui/icons-material/AccountCircleOutlined';
import MuiAvatar from '@mui/material/Avatar';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

interface Props {
  name: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Avatar = forwardRef(
  (
    { name, size = 'medium', className, onClick = () => {} }: Props,
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    const stringToColor = (string: string) => {
      let hash = 0;
      let i;

      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = '#';

      for (i = 0; i < 3; i += 1) {
        let value = (hash >> (i * 8)) & 0xff;
        value = Math.floor(value * 0.8);
        color += `00${value.toString(16)}`.slice(-2);
      }
      return color;
    };

    const avatarProps = (name?: string) => {
      const noName = !name || name.length === 0;

      return {
        sx: { bgcolor: noName ? 'var(--neutral)' : stringToColor(name) },
        children: noName ? (
          <PersonIcon />
        ) : (
          name
            .split(' ')
            .map((n) => n[0])
            .join('')
        ),
      };
    };

    return (
      <AvatarStyled
        ref={ref}
        className={className}
        size={size}
        {...avatarProps(name)}
        onClick={onClick}
      />
    );
  },
);

const AvatarStyled = styled(MuiAvatar)<{ size: string }>`
  &&& {
    color: white;
    ${({ size }) =>
      size === 'small' &&
      css`
        width: 18px;
        height: 18px;
        font-size: 9px;
      `}
    ${({ size }) =>
      size === 'medium' &&
      css`
        width: 25px;
        height: 25px;
        font-size: 11px;
      `}
  ${({ size }) =>
      size === 'large' &&
      css`
        width: 35px;
        height: 35px;
        font-size: 14px;
      `}
  }
`;

export default memo(Avatar);
