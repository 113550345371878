import Linkify from 'linkify-react';

const textWithLineBreaks = (text?: string, formatInside?: boolean) => {
  const formatString = (str: string) => {
    if (!formatInside) return str;

    if (str.toLowerCase().startsWith('агент:') || str.toLowerCase().startsWith('условия:')) {
      const split = str.split(':');
      return (
        <>
          <b>{split[0]}:</b> {split[1]}
        </>
      );
    } else return str;
  };

  return text?.split('\n').map((str, index) => (
    <Linkify as="p" key={index} options={{ target: '_blank' }}>
      {formatString(str)}
    </Linkify>
  ));
};

export default textWithLineBreaks;
