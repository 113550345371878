import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/ClearRounded';
import unionBy from 'lodash/unionBy';
import styled from '@emotion/styled/macro';

interface Props {
  files: File[];
  setFiles: (files: File[]) => void;
  accept?: string;
  label?: string;
}

const FileInput = ({
  files,
  setFiles,
  accept = 'image/*, .pdf, .doc, .docx, .xml',
  label = 'Прикрепить файлы',
}: Props) => {
  const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    setFiles(unionBy(files, Array.from(e.target.files) as File[], 'name'));
    e.target.value = '';
  };

  const handleRemoveFile = (file: File) => {
    setFiles(files.filter((f) => f !== file));
  };

  return (
    <FileInputStyled>
      <InputTrigger onClick={(e) => e.stopPropagation()}>
        <AttachFileIcon fontSize="small" />
        {label}
        <input
          type="file"
          multiple
          onChange={handleSelectFiles}
          style={{ display: 'none' }}
          accept={accept}
        />
      </InputTrigger>
      {files.length > 0 && (
        <Files>
          {files.map((file) => (
            <File key={file.name}>
              {file.name}
              <ClearIconStyled onClick={() => handleRemoveFile(file)} />
            </File>
          ))}
        </Files>
      )}
    </FileInputStyled>
  );
};

export default FileInput;

const FileInputStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px dashed var(--neutral-border);
  border-radius: var(--radii-md);
  overflow: hidden;
`;

const InputTrigger = styled.label`
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  padding: var(--space-sm);
  font-size: var(--fz-sm);
  color: var(--text-subtle);
  border-bottom: 1px dashed var(--neutral-border);
  cursor: pointer;
  transition: var(--transition);
  &:hover {
    color: var(--primary-hover);
    background-color: var(--primary-light);
  }
  &:last-child {
    border-bottom: none;
  }
`;

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-sm);
  padding: var(--space-sm);
`;

const File = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-xxs);
  font-size: var(--fz-sm);
`;

const ClearIconStyled = styled(ClearIcon)`
  margin-top: 1px;
  font-size: var(--fz-md);
  color: var(--error);
  cursor: pointer;
  &:hover {
    color: var(--error-hover);
  }
`;
