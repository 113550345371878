import styled from '@emotion/styled/macro';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--space-md);

  @media (max-width: 768px) {
    margin: 0;
    padding: var(--space-md);
  }
`;

export const Title = styled.h2`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: var(--space-md);
  margin: 0;
  font-size: var(--fz-xl);
  font-weight: 500;
  text-align: left;

  @media (max-width: 768px) {
    font-size: var(--fz-lg);
  }
`;
