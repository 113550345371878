import { graphql } from 'gql';

import { client } from './client';
import * as GQLTypes from 'gql/graphql';

export type { TeamFragment, TeamShortFragment, TeamShortWithLogoFragment } from 'gql/graphql';

graphql(`
  fragment TeamShort on Team {
    id
    name
  }
`);

graphql(`
  fragment TeamShortWithLogo on Team {
    ...TeamShort
    logo
  }
`);

graphql(`
  fragment Team on Team {
    id
    name
    logo
    ageGroup {
      name
    }
    players {
      id
      name
      primaryPosition {
        name
        wsPosition
      }
      tmUrl
      image
      marketValue
      height
      age
      birthDate
    }
  }
`);

const SEARCH_TEAMS_QUERY = graphql(`
  query SearchTeams($query: String, $first: Int, $after: String) {
    searchTeams(query: $query, first: $first, after: $after) {
      ... on TeamConnection {
        nodes {
          ...TeamShort
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }

      ... on SearchTeamsQueryDomainError {
        code
        message
      }
    }
  }
`);

const CREATE_TEAM_MUTATION = graphql(`
  mutation CreateTeam($input: TeamCreateInput!) {
    team {
      create(input: $input) {
        team {
          ...TeamShort
        }
        error {
          code
          message
        }
      }
    }
  }
`);

const teamService = {
  searchTeams: async (variables: GQLTypes.SearchTeamsQueryVariables) => {
    const result = await client.query({
      query: SEARCH_TEAMS_QUERY,
      variables,
    });

    if (result.data.searchTeams.__typename === 'SearchTeamsQueryDomainError') {
      return Promise.reject(result.data.searchTeams);
    }

    return result.data.searchTeams.nodes;
  },

  createTeam: async (input: GQLTypes.TeamCreateInput) => {
    const result = await client.mutate({
      mutation: CREATE_TEAM_MUTATION,
      variables: { input },
    });

    if (result.data.team.create.error) {
      return Promise.reject(result.data.team.create.error);
    }

    return result.data.team.create.team;
  },
};

export default teamService;
