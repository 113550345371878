import uniqBy from 'lodash/uniqBy';
import Cookies from 'universal-cookie';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { GQLType } from 'shared/api';
import userService, {
  AnotherUserInOrgFragment,
  CurrentOrgUser,
  CurrentUser,
  OrgRolePermissionActionEnum,
} from 'shared/api/user';

const cookiesService = new Cookies();

const defaultUser: CurrentUser = {
  user: {
    id: '',
    email: '',
    name: '',
    isSuperadmin: false,
  },
  orgUsers: [],
};

export type UserStore = {
  identified: boolean;
  authenticated: boolean;
  hasAccessToken: boolean;
  record: CurrentUser | null;
  currentOrgUser?: CurrentOrgUser;
  currentPermissions: OrgRolePermissionActionEnum[];
  anotherUsersInOrg: AnotherUserInOrgFragment[];
};

export type UserActions = {
  setCurrentOrg: (orgUser: CurrentOrgUser) => void;
  logOut: () => Promise<void>;
  setUser: (record: CurrentUser) => void;
  fetchUser: () => Promise<CurrentUser>;
  setSessionTokens: (sessionTokens: GQLType.SessionTokens) => void;
  removeSessionTokens: () => void;
  setOrgId: (orgId: string) => void;
  signIn: (input: GQLType.SessionCreateWithPasswordInput) => Promise<void>;
  checkPermission: (permission: OrgRolePermissionActionEnum) => boolean;
  fetchAnotherUsersInOrg: (orgRoleIds?: string[]) => Promise<AnotherUserInOrgFragment[]>;
};

export const useUserStore = create(
  immer<UserStore & UserActions, []>((set, get) => ({
    record: defaultUser,

    hasAccessToken: cookiesService.get('accessToken'),
    identified: false,
    authenticated: false,
    currentPermissions: [],
    anotherUsersInOrg: [],

    setSessionTokens: (sessionTokens: GQLType.SessionTokens) => {
      const { accessToken, refreshToken, expiresAt } = sessionTokens;

      cookiesService.set('accessToken', accessToken, { path: '/', expires: new Date(expiresAt) });
      cookiesService.set('refreshToken', refreshToken, {
        path: '/',
        expires: new Date(expiresAt),
      });
      cookiesService.set('tokenExpiresAt', expiresAt, {
        path: '/',
        expires: new Date(expiresAt),
      });
    },

    removeSessionTokens: () => {
      cookiesService.remove('accessToken', { path: '/' });
      cookiesService.remove('refreshToken', { path: '/' });
      cookiesService.remove('tokenExpiresAt', { path: '/' });
    },

    setOrgId: (orgId) => {
      cookiesService.set('orgId', orgId, {
        path: '/',
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      });
    },

    setCurrentOrg: (orgUser) => {
      set((state) => {
        state.currentOrgUser = orgUser;
        state.currentPermissions = orgUser.orgRole.orgRolePermissions.map(
          (permission) => permission.action,
        );
      });
      get().setOrgId(orgUser.organization.id);
    },

    signIn: async (input) => {
      try {
        const sessionTokens = await userService.signIn(input);

        get().setSessionTokens(sessionTokens);
        set({ authenticated: true, hasAccessToken: true });
      } catch (exx) {
        return Promise.reject(exx);
      }
    },

    setUser: (record) => {
      const cookies = cookiesService.getAll();
      const currentOrgUser = record.orgUsers.find(
        (orgUser: CurrentOrgUser) => orgUser.organization.id === cookies.orgId,
      );
      set((state) => {
        state.record = record;
        state.identified = true;
      });
      get().setCurrentOrg(currentOrgUser || record.orgUsers[0]);
    },

    logOut: async () => {
      try {
        await userService.logOut();

        get().removeSessionTokens();
      } catch (exx) {
        return Promise.reject(exx);
      }
    },

    fetchUser: async () => {
      const cookies = cookiesService.getAll();
      let currentIdentity = await userService.getUser(cookies.orgId);

      if (cookies.orgId) {
        // get all available orgs without permissions info and add them into the current identity
        const availableOrgs = await userService.getAvailableOrgs();
        const normalizedOrgs = availableOrgs.map((orgUser: CurrentOrgUser) => ({
          ...orgUser,
          orgRole: { id: '0', name: '', orgRolePermissions: [] },
        }));

        currentIdentity = {
          ...currentIdentity,
          orgUsers: uniqBy([...currentIdentity.orgUsers, ...normalizedOrgs], 'organization.id'),
        };
      }

      get().setUser(currentIdentity);

      return currentIdentity;
    },

    fetchAnotherUsersInOrg: async (orgRoleIds) => {
      if (get().anotherUsersInOrg.length > 0) return;

      const cookies = cookiesService.getAll();
      const anotherUsersInOrg = await userService.getAnotherUsersInOrg(cookies.orgId, orgRoleIds);

      set((state) => {
        state.anotherUsersInOrg = anotherUsersInOrg;
      });

      return anotherUsersInOrg;
    },

    checkPermission: (permission: OrgRolePermissionActionEnum) => {
      return get().currentPermissions.includes(permission);
    },
  })),
);

export default useUserStore;
