import LogoutIcon from '@mui/icons-material/ExitToAppRounded';
import { useMediaQuery } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

import { MobileMenuItem } from './MobileMenu';
import { useUserStore } from 'entities/User.model';

const Logout = () => {
  const navigate = useNavigate();
  const { logOut } = useUserStore();
  const smallScreen = useMediaQuery('(max-width:768px)');

  const handleLogOut = async () => {
    try {
      await logOut();
      navigate('/user/sign-in');
    } catch (exx) {}
  };

  return smallScreen ? (
    <MobileMenuItem onClick={handleLogOut}>
      <LogoutIcon /> Выйти
    </MobileMenuItem>
  ) : (
    <MenuItem onClick={handleLogOut}>
      <ListItemIcon>
        <LogoutIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Выйти</ListItemText>
    </MenuItem>
  );
};

export default Logout;
