import { useEffect, useState } from 'react';
import MeasurementIcon from '@mui/icons-material/AssessmentOutlined';
import MissingIcon from '@mui/icons-material/AssignmentLateOutlined';
import CloseIcon from '@mui/icons-material/Close';
import TrainingIcon from '@mui/icons-material/FitnessCenterOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import BudgetIcon from '@mui/icons-material/PaymentsOutlined';
import MatchIcon from '@mui/icons-material/SportsSoccerOutlined';
import StarIcon from '@mui/icons-material/StarBorderOutlined';
import ScoutingIcon from '@mui/icons-material/VisibilityOutlined';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { NavLink, useLocation } from 'react-router-dom';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

import UserbarMobile from './UserbarMobile';
import useCheckSportBudgetPermission from '../../hooks/useCheckSportBudgetPermission';
import { routes } from 'app/Router';
import useUserStore from 'entities/User.model';
import { OrgRolePermissionActionEnum } from 'shared/api/user';

const MobileMenu = () => {
  const { pathname } = useLocation();
  const { checkPermission } = useUserStore();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { isSportBudgetPageAllowed } = useCheckSportBudgetPermission();

  const handleClose = () => setOpenDrawer(false);

  useEffect(() => {
    setOpenDrawer(false);
  }, [pathname]);

  const renderLink = (to: string, label: string, icon: React.ReactNode) => (
    <LinkStyled to={to}>
      {icon}
      {label}
    </LinkStyled>
  );

  return (
    <>
      <IconButton size="small" onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </IconButton>

      <DrawerStyled anchor="right" open={openDrawer} onClose={handleClose}>
        <Header>
          <Title>Портал</Title>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Header>

        <Links>
          {renderLink(routes.club, 'Команда', <StarIcon />)}
          {renderLink(routes.trainings, 'Тренировки', <TrainingIcon />)}
          {renderLink(routes.matches, 'Матчи', <MatchIcon />)}
          {renderLink(routes.measurements, 'Данные', <MeasurementIcon />)}
          {renderLink(routes.missingPlayer, 'Отсутствия', <MissingIcon />)}
          {checkPermission(OrgRolePermissionActionEnum.ScoutingResearchingShow) &&
            renderLink(routes.scouting, 'Селекция', <ScoutingIcon />)}
          {isSportBudgetPageAllowed &&
            renderLink(routes.sportBudget, 'Спортивный бюджет', <BudgetIcon />)}
        </Links>

        <UserbarMobile />
      </DrawerStyled>
    </>
  );
};

export default MobileMenu;

const DrawerStyled = styled(Drawer)`
  .MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 275px;
  }
`;

const Header = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 var(--space-md);
  padding-top: 4px;
  background-color: var(--slate-5);

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-image: linear-gradient(to right, var(--error) 50%, var(--primary) 50%);
    background-size: 100%;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Title = styled.div`
  margin: 0;
  font-size: var(--fz-lg);
  font-weight: 500;
  line-height: 35px;
`;

const Links = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  padding: var(--space-md) var(--space-xs);
  overflow-y: auto;
`;

const MobileMenuItemStyles = css`
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  padding: var(--space-xs) var(--space-sm);
  font-size: var(--fz-md);
  color: var(--text-subtle);
  border-radius: var(--radii-sm);
  text-decoration: none;
  transition: background-color var(--transition);
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    margin-top: 1px;
  }

  &:hover {
    background-color: var(--neutral-light);
    color: var(--text);
  }

  &.active {
    color: white;
    background-color: var(--primary);
  }
`;

const LinkStyled = styled(NavLink)`
  ${MobileMenuItemStyles}
`;

export const MobileMenuItem = styled.div`
  ${MobileMenuItemStyles}
`;
